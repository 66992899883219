import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, CircularProgress, Container, Grid, Paper, Snackbar, TextField } from '@material-ui/core';
import { Facebook, Instagram } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { useForm } from 'react-hook-form';

import logo from '../assets/img/logo_smillias.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 30,
  },
  logo: {
    height: 80,
  },
  address: {
    paddingTop: 20,
  },
  materialIcon: {
    verticalAlign: 'middle',
    color: '#6FB640',
    margin: 5,
  },
  buttonProgress: {
    color: '#FFFFFF',
    marginLeft: 20,
    marginRight: 20,
  },
}));

export default function Contact() {
  const classes = useStyles();
  const { register, errors, handleSubmit, reset } = useForm();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const onSubmit = (data, e) => {
    const templateParams = {
      firstname: data.firstname,
      lastname: data.lastname,
      telephone: data.telephone,
      email: data.email,
      message: data.message,
    };

    console.log(data);

    if (!loading) {
      setLoading(true);
    }

    window.emailjs.send('service_fz63e3t', 'template_1sfaw6h', templateParams).then(
      function (response) {
        console.log('SUCCESS!', response.status, response.text);
        e.target.reset();
        setOpen(true);
        setLoading(false);
      },
      function (error) {
        console.log('FAILED...', error);
      }
    );
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Container>
        <span id='contact' className='anchor'></span>
        <h2 className='title'>Contactez-nous</h2>
        <div className={classes.root}>
          <Grid container justify='space-around' alignItems='flex-start' spacing={1}>
            <Grid item xs={12} md={7}>
              <form noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
                <Paper style={{ padding: 20 }}>
                  <p>
                    Une question, un devis ? Précisez votre demande en remplissant le formulaire ci-dessous.
                    <br />
                    Nous nous engageons à vous répondre dans les plus brefs délais.
                    <br />
                    <br />
                    (Les champs marqués d'une astérisque <sup>*</sup> sont obligatoires)
                  </p>
                  <Grid container alignItems='flex-start' spacing={2}>
                    <Grid item xs={6}>
                      <TextField id='co-lastname' name='lastname' label='Nom' fullWidth required inputRef={register({ required: true })} />
                      {errors.lastname && 'Veuillez renseigner un Nom'}
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id='co-firstname'
                        name='firstname'
                        label='Prénom'
                        fullWidth
                        required
                        inputRef={register({ required: true })}
                      />
                      {errors.firstname && 'Veuillez renseigner un Prénom'}
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id='co-telephone'
                        name='telephone'
                        label='Téléphone'
                        type='tel'
                        fullWidth
                        required
                        inputRef={register({ required: true, minLength: 10, maxLength: 10, pattern: /\d{10}/ })}
                        onChange={(e) => {
                          e.target.value = e.target.value.replace(/\D+/, '').slice(0, 10);
                        }}
                      />
                      {errors.telephone && 'Veuillez renseigner un n° de téléphone valide'}
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id='co-email'
                        name='email'
                        label='Email'
                        fullWidth
                        inputRef={register({ pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/ })}
                      />
                      {errors.email && 'Veuillez renseigner un email valide'}
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id='co-message'
                        name='message'
                        label='Votre message'
                        rows={5}
                        multiline
                        fullWidth
                        required
                        inputRef={register({ required: true })}
                      />
                      {errors.message && 'Veuillez formuler votre demande'}
                    </Grid>
                    <Grid container justify='flex-end' spacing={1}>
                      <Grid item>
                        <Box padding={1}>
                          <Button type='reset' variant='contained' onClick={reset}>
                            Annuler
                          </Button>
                        </Box>
                      </Grid>
                      <Grid item>
                        <Box padding={1}>
                          <Button type='submit' variant='contained' color='primary'>
                            {!loading ? 'Envoyer' : <CircularProgress size={24} className={classes.buttonProgress} />}
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </form>
              <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
                <Alert variant='filled' onClose={handleClose} severity='success'>
                  Votre message a été envoyé. Nous vous répondrons dans les plus brefs délais.
                </Alert>
              </Snackbar>
            </Grid>
            <Grid item xs={12} md={3} align='center'>
              <div className={classes.address}>
                <img src={logo} alt='Logo S.Millias Paysagiste' className={classes.logo}></img>
                <p>
                  S.Millias Paysagiste
                  <br />
                  Le Montpas
                  <br />
                  73160 Vimines
                  <br />
                  <br />
                  Tél.: 06 89 23 26 57
                </p>
              </div>
              <div>
                Retrouvez nous sur les réseaux sociaux
                <Box>
                  <a href='https://www.facebook.com/st.millias' rel='noreferrer' target='_blank'>
                    <Facebook fontSize='large' className={classes.materialIcon} />
                  </a>
                  <a href='https://www.instagram.com/smilliaspaysagiste/' rel='noreferrer' target='_blank'>
                    <Instagram fontSize='large' className={classes.materialIcon} />
                  </a>
                </Box>
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>
    </React.Fragment>
  );
}
