import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Backdrop, Container, Divider, Fade, IconButton, Link, Modal } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  footer: {
    padding: theme.spacing(2, 0),
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  },
  actionModal: { textAlign: 'right' },
  closeModal: {
    marginTop: '5px',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(0, 2, 2),
    outline: 0,
  },
  mentionsLegales: {
    display: 'inline-block',
  },
}));

export default function Footer() {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <footer className={classes.footer}>
      <Container maxWidth='lg' align='center'>
        <Divider />
        <Typography variant='body2' color='textSecondary'>
          {'Copyright © S.Millias Paysagiste '}
          {new Date().getFullYear()}
          {'. '}
          <Link href='#mentions-legales' onClick={handleOpen} color='inherit' className={classes.mentionsLegales}>
            Mentions légales
          </Link>
        </Typography>
      </Container>
      <Modal
        aria-labelledby='mentions-legales-title'
        aria-describedby='mentions-legales-description'
        className={classes.modal}
        open={openModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <Container className={classes.paper}>
            <div className={classes.actionModal}>
              <IconButton size='small' onClick={handleClose} className={classes.closeModal}>
                <CloseIcon></CloseIcon>
              </IconButton>
            </div>
            <h2 id='mentions-legales-title' className='title'>
              Mentions légales
            </h2>
            <p id='mentions-legales-description'>
              <Typography variant='h6' component='h3'>
                Responsable de la rédaction et de la publication :
              </Typography>
              S.Millias Paysagiste
              <br />
              Le Montpas
              <br />
              73160 Vimines
              <br />
              Tél.: 06 89 23 26 57
              <br />
              SIRET : 812 934 305 00010
              <Typography variant='h6' component='h3'>
                Hébergement :
              </Typography>
              Firebase
              <br />
              Siège social : 188 King ST, San Fransisco, CA 94107, United States
              <br />
              https://firebase.google.com/.
              <Typography variant='h6' component='h3'>
                Crédits photos
              </Typography>
              Shutterstock - Freepik
              <Typography variant='h6' component='h3'>
                Conditions d'utilisations :
              </Typography>
              Ce site web est une œuvre de l’esprit originale. Elle bénéficie d’une protection juridique en vertu de la loi française. Cette
              œuvre, dans les pays autres que la France, est protégée par les conventions internationales et notamment la Convention de
              Berne sur la protection des œuvres littéraires et artistiques.
              <br />
              En conséquence sont autorisées :
              <ul>
                <li>La reproduction à titre privé,</li>
                <li>L’utilisation publique et la création d’un lien hypertexte sans frame, sous réserve de notre acceptation préalable.</li>
              </ul>
            </p>
          </Container>
        </Fade>
      </Modal>
    </footer>
  );
}
