import { Container } from '@material-ui/core';
import React from 'react';

export default function About() {
  return (
    <React.Fragment>
      <Container>
        <span id='qui-sommes-nous' className='anchor'></span>
        <h2 className='title'>Qui sommes-nous ?</h2>
        <p>
          Avec plus de 20 ans d'expérience, l'entreprise S.Millias Paysagiste vous accompagne dans vos projets d'aménagements paysagers et
          pour l'entretien de vos espaces verts. Particuliers, jardins privés, copropriétés, chaque espace est unique et nous mettons tout
          notre savoir-faire pour mener à bien votre projet.
        </p>
        <p>
          Fortement implanté sur le bassin chambérien, nous intervenons sur Aix les bains, Chambéry, Cognin, Montmélian et les communes
          alentours. N'hésitez pas à nous contacter pour savoir si nous intervenons sur votre commune.
        </p>
      </Container>
    </React.Fragment>
  );
}
