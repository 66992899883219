import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Divider, Grid, List, ListItem, ListItemText, Typography } from '@material-ui/core';
import illustrationSavoirFaire from '../assets/img/illustration_savoir-faire.svg';

const useStyles = makeStyles((theme) => ({
  listFirst: {
    '& > li': {
      textAlign: 'right',
      padding: '0 10px',
    },
  },
  listSecond: {
    '& > li': {
      textAlign: 'left',
      padding: '0 10px',
    },
  },
  title: {
    fontFamily: 'Dancing Script, cursive',
    fontSize: '3rem',
    color: '#6FB640',
  },
  illustration: {
    marginTop: '-5vh',
    marginBottom: 0,
  },
}));

export default function Expertise() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Container>
        <span id='savoir-faire' className='anchor'></span>
        <h2 className='title'>Notre savoir-faire</h2>
        <div>
          <Grid container justify='space-around' alignItems='flex-start' spacing={4}>
            <Grid item xs={6} md={4}>
              <Typography variant='h5' component='h3' align='center' className={classes.title}>
                Création
              </Typography>
              <Divider />
              <List className={classes.listFirst}>
                <ListItem>
                  <ListItemText primary='Pavage' />
                </ListItem>
                <ListItem>
                  <ListItemText primary='Dallage' />
                </ListItem>
                <ListItem>
                  <ListItemText primary='Clôture' />
                </ListItem>
                <ListItem>
                  <ListItemText primary='Terrasse bois' />
                </ListItem>
                <ListItem>
                  <ListItemText primary='Plantation arbuste' />
                </ListItem>
                <ListItem>
                  <ListItemText primary='Engazonnement' />
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={6} md={4}>
              <Typography variant='h5' component='h3' align='center' className={classes.title}>
                Entretien
              </Typography>
              <Divider />
              <List className={classes.listSecond}>
                <ListItem>
                  <ListItemText primary='Tonte' />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Taille d'arbustes et de haies" />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Taille d'arbres" />
                </ListItem>
                <ListItem>
                  <ListItemText primary='Service à la personne:' secondary="jusqu'à 50% d'avantages fiscaux avec notre coopérative" />
                </ListItem>
              </List>
            </Grid>
          </Grid>
          <img src={illustrationSavoirFaire} className={classes.illustration} alt='Illustration savoir-faire' />
        </div>
      </Container>
    </React.Fragment>
  );
}
