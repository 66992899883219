import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Toolbar,
  AppBar,
  Container,
  Typography,
  Button,
  IconButton,
  Slide,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Box,
  Divider,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import logo from '../assets/img/logo_smillias.svg';
import bannerImg from '../assets/img/design_exterieur.jpg';
import { ChevronRightRounded } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 0,
  },
  logo: {
    height: 40,
  },
  menuButtons: {
    '& > *': {
      marginLeft: 10,
    },
  },
  banner: {
    display: 'flex',
    alignItems: 'flex-end',
    marginTop: 50,
    height: '50vh',
    backgroundImage: `url(${bannerImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    color: '#fff',
  },
  textBanner: {
    paddingLeft: 20,
    marginBottom: '3vh',
  },
  cursive: {
    fontFamily: 'Dancing Script, cursive',
    fontWeight: 700,
    lineHeight: '1.5rem',
  },
}));

export default function Header() {
  const classes = useStyles();
  const [state, setState] = useState({
    mobileView: false,
    right: false,
  });
  const { mobileView } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };
    setResponsiveness();
    window.addEventListener('resize', () => setResponsiveness());
  }, []);

  const displayDesktop = () => {
    return (
      <div className={classes.menuButtons}>
        <Button href='#qui-sommes-nous'>Qui sommes-nous ?</Button>
        <Button href='#savoir-faire'>Savoir-faire</Button>
        <Button href='#realisations'>Réalisations</Button>
        <Button href='#contact'>Contact</Button>
      </div>
    );
  };

  const toggleDrawer = (anchor, open) => (event) => {
    setState({ ...state, [anchor]: open });
  };

  const displayMobile = () => {
    return (
      <Toolbar>
        <IconButton
          onClick={toggleDrawer('right', true)}
          {...{
            edge: 'end',
          }}
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>
    );
  };

  return (
    <React.Fragment>
      <AppBar position='fixed' color='inherit' elevation={2}>
        <Container maxWidth='lg'>
          <Toolbar className={classes.toolbar}>
            <a href='/'>
              <img src={logo} alt='Logo S.Millias Paysagiste' className={classes.logo}></img>
            </a>
            {mobileView ? displayMobile() : displayDesktop()}
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer anchor='right' open={state['right']} onClose={toggleDrawer('right', false)}>
        <List>
          <ListItem button key='qui-sommes-nous' component='a' href='#qui-sommes-nous' onClick={toggleDrawer('right', false)}>
            <ListItemIcon>
              <ChevronRightRounded></ChevronRightRounded>
            </ListItemIcon>
            <ListItemText primary='Qui sommes-nous ?' />
          </ListItem>
          <ListItem button key='savoir-faire' component='a' href='#savoir-faire' onClick={toggleDrawer('right', false)}>
            <ListItemIcon>
              <ChevronRightRounded></ChevronRightRounded>
            </ListItemIcon>
            <ListItemText primary='Savoir-faire' />
          </ListItem>
          <ListItem button key='realisations' component='a' href='#realisations' onClick={toggleDrawer('right', false)}>
            <ListItemIcon>
              <ChevronRightRounded></ChevronRightRounded>
            </ListItemIcon>
            <ListItemText primary='Réalisations' />
          </ListItem>
          <ListItem button key='contact' component='a' href='#contact' onClick={toggleDrawer('right', false)}>
            <ListItemIcon>
              <ChevronRightRounded></ChevronRightRounded>
            </ListItemIcon>
            <ListItemText primary='Contact' />
          </ListItem>
        </List>
        <Divider></Divider>
        <Box align='center' marginTop={3}>
          <img src={logo} alt='Logo S.Millias Paysagiste' className={classes.logo}></img>
          <p>
            S.Millias Paysagiste
            <br />
            Le Montpas
            <br />
            73160 Vimines
            <br />
            <br />
            Tél.: <a href='tel:0689232657'>06 89 23 26 57</a>
          </p>
        </Box>
      </Drawer>
      <div className={classes.banner}>
        <div className={classes.textBanner}>
          <Slide direction='down' in={true} timeout={500}>
            <Typography variant='h4' component='h1' className={classes.cursive}>
              Concevons ensemble
            </Typography>
          </Slide>
          <Slide direction='down' in={true} timeout={300}>
            <Typography variant='h3' component='h1'>
              vos extérieurs
            </Typography>
          </Slide>
        </div>
      </div>
    </React.Fragment>
  );
}
