import React from 'react';
import { Container, CssBaseline } from '@material-ui/core';
import Header from './components/Header';
import About from './components/About';
import Expertise from './components/Expertise';
import Portfolio from './components/Portfolio';
import Contact from './components/Contact';
import Footer from './components/Footer';

import './App.css';

export default function App() {
  return (
    <React.Fragment>
      <CssBaseline />
      <Container disableGutters={true}>
        <Header />
        <About />
        <Expertise />
        <Portfolio />
        <Contact />
        <Footer />
      </Container>
    </React.Fragment>
  );
}
