import React, { useState, useEffect } from 'react';
import { Container, makeStyles, Grid, Card, CardActionArea, CardContent, CardMedia, Button } from '@material-ui/core';
import { Visibility } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  portfolio: {
    display: 'flex',
    paddingBottom: '30px',
    background: 'linear-gradient(180deg, #B6450E 10%, #652815 90%)',
    justifyContent: 'center',
    alignItems: 'top',
    color: '#fff'
  },
  card: {
    maxWidth: '250px'
  },
  cardContent: {
    fontFamily: 'Raleway, sans-serif',
    fontSize: '1.25rem',
    textAlign: 'center',
    padding: '0.25em'
  },
  mediaLinkContainer: {
    textAlign: 'center',
    marginTop: '20px'
  },
  mediaLink: {
    color: '#FFFFFF',
    borderColor: '#FFFFFF',
    backgroundColor: 'rgba(255, 255, 255, 0.1)'
  }
}));

export default function Portfolio() {
  const classes = useStyles();
  const [medias, setMedias] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [mediaOffset, setMediaOffset] = useState(1);

  useEffect(() => {
    fetch('https://smilliaspaysagiste.pythonanywhere.com/instagram/', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setLoaded(true);
        setMedias(data);
      });
  }, [loaded]);

  const displayMedias = () => {
    if (medias !== null) {
      let mediaNumber = 4;
      let mediaIndex = 0;

      return medias.map((media) => {
        mediaIndex += 1;
        if (mediaIndex <= mediaNumber * mediaOffset) {
          return (
            <Grid item key={media.id}>
              <Card elevation={5} className={[classes.card, 'card__box'].join(' ')}>
                <CardActionArea>
                  <a href={media.permalink} target='_blank' rel='noreferrer'>
                    <CardMedia component='img' image={media.url} />
                  </a>
                  <CardContent className={classes.cardContent}>{media.hashtag}</CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          );
        } else {
          return '';
        }
      });
    } else {
      return '';
    }
  };

  const setMediasLink = () => {
    let buttonText = 'Voir plus de réalisations';
    let buttonHref = '';
    if (mediaOffset >= 2) {
      buttonText = 'Voir plus sur Instagram';
      buttonHref = 'https://www.instagram.com/smilliaspaysagiste/';
    }
    return (
      <Button
        variant='outlined'
        className={classes.mediaLink}
        size='large'
        startIcon={<Visibility />}
        onClick={() => setMediaOffset(mediaOffset + 1)}
        href={buttonHref}
        target='_blank'
        rel='noreferrer'
      >
        {buttonText}
      </Button>
    );
  };

  return (
    <React.Fragment>
      <Container className={classes.portfolio}>
        <span id='realisations' className='anchor'></span>
        <Grid container justify='space-around' alignItems='flex-start' spacing={3} className={classes.grid}>
          <Grid item xs={12}>
            <h2>Nos réalisations</h2>
          </Grid>
          {displayMedias()}
          <Grid item xs={12} className={classes.mediaLinkContainer}>
            {setMediasLink()}
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
